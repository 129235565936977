<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <a class="navbar-brand" href="#"><b>RW Messenger</b></a>

      <div class="collapse navbar-collapse justify-content-between">
        <!-- side nav -->
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="#" @click="changeStatus()">
              <i
                class="fas fa-circle"
                :class="staffStatus.online ? 'online' : 'away'"
              ></i>
              {{ staffStatus.message }}
            </a>
          </li>
          <!-- <li class="nav-item">
		        <a class="nav-link" href="#">Menu 1</a>
		      </li>
		      <li class="nav-item">
		        <a class="nav-link" href="#">Menu 2</a>
		      </li> -->
        </ul>

        <!-- right nav -->
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbardrop"
              data-toggle="dropdown"
            >
              <i class="fa fa-user-circle"></i> John Doe
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#">Profile</a>
              <a class="dropdown-item" href="#" @click="signOut">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#3498db"
      loader="bars"
    ></loading>
  </nav>
</template>

<script>
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "NavHeader",
  components: {
    loading
  },
  data() {
    return {
      staffStatus: {
        online: true,
        message: "You're Online"
      },
      isLoading: false
    };
  },
  methods: {
    changeStatus: function() {
      let text = this.staffStatus.online ? "Away" : "Online";
      this.$swal({
        title: "Warning",
        html: "Are you sure want to be <b>" + text + "</b> ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "NO"
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.staffStatus.online = !this.staffStatus.online;
          this.staffStatus.message = this.staffStatus.online
            ? "You're Online"
            : "You're Away";
          this.$swal("Success", "Now you're " + text + ".", "success");
        }
      });
    },
    signOut: function() {
      this.isLoading = !this.isLoading;
      localStorage.removeItem("login");
      setTimeout(() => {
        this.isLoading = !this.isLoading;
        this.$router.push("/");
      }, 3000);
    }
  }
};
</script>

<style scoped>
nav.navbar {
  background: #17a2b8; /*#40D9FF;*/
}
a {
  color: white;
}
.dropdown-menu {
  background: #40d9ff;
}
.dropdown-menu > a {
  /*color: black;*/
}
.online {
  color: #93d316;
  /*animation: blinker 2s linear infinite;*/
}
.away {
  color: #ffc107;
  /*animation: blinker 2s linear infinite;*/
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
