<template>
  <div>
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/layout/NavHeader.vue";
export default {
  components: {
    Navbar
  },
  mounted() {
    if (!localStorage.getItem("login")) {
      this.$router.push("/");
    }
  }
};
</script>
